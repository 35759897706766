<template>
    <div class="journalism ">
        <div class=" top_banner">
            <div class="topHeader">
                <ul>
                    <li>
                        <router-link :to="path">{{ title }}</router-link>
                        <i class="el-icon-arrow-right"></i>
                        {{ content }}
                    </li>
                </ul>
            </div>
            <div class="flex max-with">
                <img :src="info.thumbnail"/>
                <div class="flexC JustifyContentSB">
                    <div class="flexC JustifyContentSB">
                        <h2 style="margin-top: 20px">{{ info.name }}</h2>
                        <p>课时数：{{ info.lessonCnt }}</p>
                        <p>学习人次：{{ info.learnCnt }}</p>
                    </div>
                    <span>
                        <el-button type="warning" @click="modalShowFun('')">立即学习</el-button>
                    </span>
                </div>
            </div>
        </div>

        <div class="main-con">
            <div class="flexC main">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="课程介绍" name="0">
                        <div class="pane_item_box">
                            <div v-if="imgList" v-html="imgList">
                                <!--                            <img
                                                                v-for="(item, index) in imgList"
                                                                :key="index"
                                                                :src="item"
                                                                style="max-width: 100%;"
                                                            />-->
                            </div>
                            <span v-else>{{desc}}</span>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="章节目录" name="1">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item
                                v-for="(item, index) in dataList"
                                :key="index"
                                :name="index"
                                :title="item.name"
                            >
<!--                                <template slot="title">
                                    <div class="lessonTitle">{{item.name}}</div>
                                </template>-->
                                <ul>
                                    <li
                                        v-for="(val, key) in item.videos"
                                        :key="key"
                                        class="flex JustifyContentSB AlignItemsC"
                                    >
                                        <p class="flex  AlignItemsC">
                                            <i></i>
                                            {{ val.name }}
                                        </p>
                                        <span
                                        ><el-button
                                            round
                                            size="mini"
                                            type="warning"
                                            @click="modalShowFun(val.url)"
                                        >立即学习</el-button
                                        ></span
                                        >
                                    </li>
                                </ul>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- 弹框 -->
        <div
            v-show="show"
            class=" flexC modal JustifyContentC AlignItemsC "
        >
            <video
                id="videoEl"
                v-if="videoUrl"
                controls
                :src="videoUrl"></video>
            <el-image v-else :src="require('../../assets/images/common/userQC.png')"></el-image>
            <i class=" el-icon-circle-close" @click="closeBtn"></i>
        </div>
    </div>
</template>
<script>

export default {
    name: "courseDetails",
    data() {
        return {
            activeName: "0",
            activeNames: [0],
            show: false,
            info: {},
            courseType: "",
            imgList: '',
            dataList: [],
            videoUrl: '',
            desc: '',
            title: '',
            path: '',
            content: '详情'
        };
    },
    beforeRouteEnter (to, from, next) {
        let info = {
            title: from.meta.title,
            path: from.path
        }
        localStorage.setItem('detailsInfo', JSON.stringify(info))
        next()
    },
    mounted() {
        this.getCourseDetail();
        let info = JSON.parse(localStorage.getItem('detailsInfo'))
        this.title = info.title
        this.path = info.path
    },
    methods: {
        modalShowFun(url) {
            this.show = true;
            url = url || this.dataList[0].videos[0].url || ''
            this.videoUrl = url
        },
        // 获取课程详情
        getCourseDetail() {
            let data = JSON.parse(localStorage.getItem('courseDetailsData'))
            this.info = data
            this.dataList = data.lessons || []
            if (data.description.indexOf('img') !== -1) {
                this.imgList = data.description || ''
            } else {
                this.desc = data.description
            }
        },
        closeBtn () {
            this.show = false
            if (this.videoUrl) {
                let v = document.getElementById('videoEl')
                v.pause()
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.journalism {
    background: #fafafa;
    min-height: 100vh;

    .topHeader {

        ul {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            a {
                color: #333333;
                cursor: pointer;
            }
            .el-icon-arrow-right {
                margin: 0 0.5rem;
                color: #333333;
            }
        }
    }
    .modal {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.5);

        .el-image {
            width: 217px;
            height: 217px;
            background: #ffffff;
        }

        i {
            margin-top: 16px;
            font-size: 32px;
            color: rgb(255, 255, 255, 0.59);
        }

        video {
            width: 50%;
            height: 50%;
        }
    }

    .top_banner {
        padding-top: 26px;
        padding-bottom: 50px;
        background: #fafafa;
        width: 1200px;
        margin: auto;

        img {
            width: 539px;
            height: 304px;
            margin-top: 23px;
            margin-right: 28px;
        }

        h1 {
            font-size: 32px;
            font-weight: bold;
            color: #333333;
            line-height: 72px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 54px;
        }
    }

    .main-con {
        width: 100%;
        height: 100%;
        min-width: 1200px;
        background: #FFFFFF;
    }

    .main {
        width: 1200px;
        margin: auto;
        padding-bottom: 300px;

        .pane_item_box {
            // width: 819px;
            // height: 599px;
            // img {
            // 	width: 819px;
            // 	height: 599px;
            // }
        }

        ul {
            li {
                height: 56px;
                padding: 0 16px;
                font-size: 16px;
                font-weight: 400;
                color: #1e1e1e;

                img {
                    width: 28px;
                    height: 28px;
                }

                i {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    margin-right: 19px;
                    background: url("../../assets/images/course/icon-right1.png");
                    background-size: 100% 100%;
                }
            }

            li:hover {
                background: #fffaf2;
                border-radius: 2px;

                i {
                    display: inline-block;
                    width: 28px;
                    height: 28px;
                    margin-right: 19px;
                    background: url("../../assets/images/course/icon-right.png");
                    background-size: 100% 100%;
                }
            }
        }
    }

    ::v-deep {
        .el-tabs {
            margin: 37px 0;
        }

        .el-tabs__item {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
        }

        .el-tabs__item.is-active {
            color: #ff9e18;
        }

        .el-tabs__active-bar {
            height: 4px;
            background: #ff9e18;
        }

        .el-collapse,
        .el-collapse-item__wrap {
            border: 0;
        }

        .el-collapse-item__header {
            font-size: 18px;
            font-weight: 400;
            color: #1e1e1e;
            border: 0;

            i {
                // font-size: 11px;
                color: #ff9e18;
            }
        }
    }
}
</style>
